import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { LatestEnComponent } from './components/journal/latest-en/latest-en.component';
import { VolumesEnComponent } from './components/journal/volumes-en/volumes-en.component';
import { EditorialEnComponent } from './components/boards/editorial-en/editorial-en.component';
import { AdvisoryEnComponent } from './components/boards/advisory-en/advisory-en.component';

import { LoginComponent } from './components/staff/login/login.component';
import { MainpageComponent } from './components/staff/mainpage/mainpage.component';

import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';
import { PublishBookComponent } from './components/staff/publish-book/publish-book.component';
import { EthicsGuidelinesComponent } from './components/ethics-guidelines/ethics-guidelines.component';
import { ObjectiveComponent } from './components/objective/objective.component';

import { ForReviewerComponent } from './components/for-reviewer/for-reviewer.component';
import { ForAuthorComponent } from './components/for-author/for-author.component';
import { adminloginArComponent } from './components/main/login/login.component';
import { bookdashbord_component } from './components/main/books_dashbord/books.component';
import { Current_Issue } from './components/main/Current_Issue/Current_Issue.component';
import { dashpordcomponent } from './components/main/dashbord/dash.component';
import { footer_component } from './components/main/footer/footer.component';
import { AimsandScopeComponent } from './components/AimsandScope/AimsandScope.component';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  // { path: 'author/home', component: MainpageComponent, canActivate: [AuthGuard] },
  // { path: 'author/publish-book', component: PublishBookComponent, canActivate: [AuthGuard] },
  // { path: 'author/login', component: LoginComponent, canActivate: [LoginGuard] },

  { path: 'home', component: HomeComponent },
  { path: 'pub-ethics-guidelines', component: EthicsGuidelinesComponent },
  { path: 'objective', component: ObjectiveComponent },

  { path: 'for-reviewer', component: ForReviewerComponent },
  { path: 'for-author', component: ForAuthorComponent },

  { path: 'journal/journal-s-volumes-and-issues/latest-issue', component: LatestEnComponent },
  { path: 'journal/journal-s-volumes-and-issues/archived-volumes-and-issues', component: VolumesEnComponent },

  
  { path: 'journal/boards/editorial-board', component: EditorialEnComponent },
  { path: 'journal/boards/advisory-board', component: AdvisoryEnComponent },
  // { path: '**', component: PageNotFoundComponent },



  
  { path: 'bookdashbord', component: bookdashbord_component },
  { path: 'x', component: bookdashbord_component },
  { path: 'admins', component: dashpordcomponent },
  { path: 'login', component: adminloginArComponent },
  { path: 'footer', component: footer_component },
  { path: 'CurrentIssue', component: Current_Issue },
  //AimsandScopeComponent
  { path: 'AimsandScope', component: AimsandScopeComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


