<div class="container-fuild">


    <header>
        <div class="background"></div>
        <div class="mask">
            <div class="container">
                <div class="context typewriter">
                    <h1 style="font-size: 45px; line-height: 70px"><span>For Reviewers</span></h1>
                </div>
            </div>
        </div>
        <div class="arrow"></div>
    </header>



    <div class="about-us-one">
        <div class="container">
            <p style="text-align: justify; font-size: 25px;">We welcome applications for becoming reviewers and/or members of the journal’s editorial board from academics with international research background. If you are interested in becoming a reviewer please send a brief email outlining your field of expertise to editor@jautsr/aut.edu.jo</p>
        <p style="text-align: justify; font-size: 25px;">Applications sent from personal email accounts will not be acknowledged.</p>
        
        
        
            <br>

            <button class='btn btn-warning' style='padding: 10px 20px; font-weight: 700; font-size: 20px;'><a href="https://www.ejmanager.com/reviewers/index.php?isl=login" target="_blank">Review Submission</a></button>
        </div>
    </div>


</div>