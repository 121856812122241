import { Component, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss'],
})
export class MainpageComponent implements OnInit {

  constructor(private _toastr: ToastrService, public _authService: AuthService, private _router: Router, private _apiService: ApiService) {}

  ngOnInit() {
  }
  




  logout() {
    this._authService.logout();
    this._router.navigate(['/home']);
  }
}
