<div class="container-fuild">


    <header>
        <div class="background"></div>
        <div class="mask">
            <div class="container">
                <div class="context typewriter">
                    <h1 style="font-size: 35px; line-height: 55px"><span>Journal of Aqaba University of Technology for
                            Studies and Research</span></h1>

                            <h1 style="font-size: 35px; line-height: 55px"><span>مجلة جامعة العقبة للتكنولوجيا للدراسات والبحوث</span></h1>
                </div>
            </div>
        </div>
        <div class="arrow"></div>
    </header>



    <!-- end new **************** -->


    <div class="about-us-one">
        <div class="container">
            <p style="text-align: justify; font-size: 25px;">Journal of Aqaba University of Technology for Studies and
                Research (JAUTSR) is an open access, peer-reviewed and refereed journal published by Aqaba University of
                Technology. The main objective of the journal is to provide an intellectual platform for all scholars
                from Jordanian and international universities.</p>
            <p style="text-align: justify; font-size: 25px;">JAUTSR is heading to become one of the leading journals for
                both humanities and natural sciences in the region. </p>
        </div>
    </div>

    <!--new **************** -->
    <div class="container-fuild mar-5">
        <div class="container-fuild">
            <div class="row">
                <div class="col-12 col-md-3">
                    <div class="buttons">
                        
                        <button  (click)="opt('CurrentIssue')" type="button" class=" fw btn btn-outline-warning"><h3 class="textopt">Current Issue</h3></button>
                        <button  (click)="sortby2('Volumes')" type="button" class=" fw btn btn-outline-warning"><h3 class="textopt">Journal Archive</h3></button>
                        <br>
                        <div style="padding: 5px;" *ngIf="x2=='Volumes'">
                            <div *ngFor="let vitem of xxxxxxxxxxxxxxxxxx">
                                <h5>#{{vitem.volume}}</h5>
                                <div *ngFor="let i of issuelist">
                                    <div>
                                        <button class=" fw2 btn btn-outline-info" (click)="issue_seected(vitem.volume+' '+i.issue)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                                            </svg>
                                            {{i.issue}}
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button  class=" fw btn btn-outline-warning"  ><a href="/pub-ethics-guidelines"><h3 class="textopt">Publication Ethics Guidelines</h3></a></button>
                        <button  class=" fw btn btn-outline-warning"  ><a href="/journal/boards/editorial-board"><h3 class="textopt">Editorial Board</h3></a></button>
                        <button  class=" fw btn btn-outline-warning"  ><a href="/journal/boards/advisory-board"><h3 class="textopt">Journal Boards Advisory Board</h3></a></button>
                        <button  class=" fw btn btn-outline-warning"  ><a href="/for-author"><h3 class="textopt">Guide for Authors</h3></a></button>
                        <button  class=" fw btn btn-outline-warning" ><a href="/for-reviewer"><h3 class="textopt">Reviewers’ Guidelines</h3></a></button>
                        <button  class=" fw btn btn-outline-warning" ><a href="/objective"><h3 class="textopt">Objective</h3></a></button>
                        <button  class=" fw btn btn-outline-warning" ><a href="/AimsandScope"><h3 class="textopt">Aims And Scope</h3></a></button>
                    </div>
                </div>
                <div class="col-12 col-md-9">
                    <div class="container-fuild">
                        <div class="row">
                            <div class="col-12 col-md-9">
                                <div class="title">
                                    <h3 class="titletext">{{title}}</h3>..
                                </div>
                                <!---  items will displaid here   -->
                                <!-- Current Issue     -->
                                

                                
                                <div class="staff">
                                    <div class="container">



                                <!-----> 
                                <div *ngIf="optactiv=='CurrentIssue'">       
                                <div *ngFor="let book of books">
                                    <div *ngIf="book.volume_issue==CurrentIssue.Current_Issuefeald">
                                        <div class="staf">
                                            <div class="row">
                                                <div class="col-md-11">
                                                    <h4>{{book.title_english}}</h4>
                                                    <p>{{book.author}}</p>
                                                    <p>{{book.email}}</p>
                                                    <p></p>
                                                    <br>
                                                    <p class="blue"><i class="fas fa-file"></i> Pages: {{book.pages}}</p>
                                                </div>
                                                    <div class="col-md-1 pdf" style="cursor: pointer;"
                                                    (click)="openBook(book.book)">
                                                    <i class="fas fa-file-pdf"></i>
                                                </div>
                                
                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                



                <div *ngIf="optactiv=='issueseected'">
                    <h5>
                        ({{issueseected}})
                    </h5>
                    <div *ngFor="let book of books">
                        <div *ngIf="book.volume_issue==issueseected">
                            <div class="staf">
                                <div class="row">
                                    <div class="col-md-11">
                                        <h4>{{book.title_english}}</h4>
                                        <p>{{book.author}}</p>
                                        <p>{{book.email}}</p>
                                        <p></p>
                                        <br>
                                        <p class="blue"><i class="fas fa-file"></i> Pages: {{book.pages}}</p>
                                    </div>
                                        <div class="col-md-1 pdf" style="cursor: pointer;"
                                        (click)="openBook(book.book)">
                                        <i class="fas fa-file-pdf"></i>
                                    </div>
                    
                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>











                                    
                                <!-- Journal Info     -->
                                    <div *ngIf="optactiv=='Journal Info'">
                                        <h1>Journal Info</h1>
                                    </div>
                                <!-- Guide for Authors     -->
                                    <div *ngIf="optactiv=='Guide for Authors'">
                                        <h1>Guide for Authors</h1>
                                    </div>
                                <!-- Reviewers’ Guidelines     -->
                                    <div *ngIf="optactiv=='Reviewers’ Guidelines'">
                                        <h1>for-reviewer</h1>
                                    </div>
























                            </div>
                            </div>

                            </div>
                            <!---  U info  -->
                            <div class="col-12 col-md-3">
                                <div class="container-fuild">
                                    <div class="row">
                                        <div class="col">
                                            <div class="info">
                                                <h4>Publisher:Aqaba University of Technology</h4>
                                                <h4>Editor-in-Chief:Dr.Mohammad Al-Wardat</h4>
                                                <h4>Section Editor: Dr.Ibrahim Altarawni</h4>
                                                <h4>Frequency: Twice a Year</h4>
                                                <h4>Online ISSN:2790-5365 </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>



</div>