import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'dashpord1',
  templateUrl: './dash.component.html',
})
export class dashpordcomponent {
    constructor(private x:ApiService,public _router: Router ) { }
    /* session var */
        set_user:any;
        set_pass:any;
        session_data(){
            this.set_user= sessionStorage.getItem('name');
            this.set_pass= sessionStorage.getItem('password');
            if (this.set_user=="user"&&this.set_pass=="pass"){

            }
            else{
                this._router.navigateByUrl(`/login`).then(() => window.location.reload())
            }
        }





    /* session var */

    ngOnInit(): void {
        this.session_data()
      }
         
      
   

}