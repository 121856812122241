<div class="container-fuild">


    <header>
        <div class="background"></div>
        <div class="mask">
            <div class="container">
                <div class="context typewriter">
                    <h1 style="font-size: 45px; line-height: 70px"><span>Objective</span></h1>
                </div>
            </div>
        </div>
        <div class="arrow"></div>
    </header>



    <div class="about-us-one">
        <div class="container">
            <p style="text-align: justify; font-size: 25px;">Journal of Aqaba University of Technology for Studies and Research (JAUTSR) is an open access, peer-reviewed and refereed journal published by Aqaba University of Technology. The main objective of the journal is to provide an intellectual platform for all scholars from Jordanian and international universities. JAUTSR is heading to become one of the leading journals for both humanities and natural sciences in the region. </p>
        </div>
    </div>


</div>