import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-reviewer',
  templateUrl: './for-reviewer.component.html',
  styleUrls: ['./for-reviewer.component.scss']
})
export class ForReviewerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
