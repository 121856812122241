<form enctype="multipart/form-data"><br>
<div class="container">
    <div class="row">
        <div id="porder" style="padding: 20px;" class="col-12 col-md-6">
            <h1 id="text_center">crate new volumes</h1>
            <hr>
            <h3>volume name</h3>
            <input [(ngModel)]="newvolume" name="newvolume" class="btn btn-outline-info" id="input" type="text">
            <br>
            <br>
            <button  (click)="postnewvolume()" id="input" class="btn btn-outline-info">create</button>
        </div>
        <div id="porder" style="padding: 20px;" class="col-12 col-md-6">
            <h1 id="text_center">crate new issue</h1>
            <hr>
            <div class="row">
                <div class="col-6">
                    <h3>issue name</h3>
                    <input [(ngModel)]="newssue" name="newssue" class="btn btn-outline-info" id="input" type="text">
                </div>
                <div class="col-6"> 
                    <h3>into volume</h3>
                    <select  [(ngModel)]="involume" name="involume" class="btn btn-outline-info" id="input" type="text">
                        <option></option>
                        <option *ngFor="let options of volumes">{{options.volume}}</option>
                    </select>
                </div>
            </div>
            <br>
            <button (click)="postnewissue()" id="input" class="btn btn-outline-info">create</button>
        </div>
    </div>
</div>
<br>
<div id="porder" class="container">
    <div class="row">
        <div class="col-6">
            <h3>chose volume</h3>
            <select (click)="logissue()" [(ngModel)]="volumeselected"  name="volumeselected" type="text" class="btn btn-outline-info" id="input">
                <option></option>
                <option *ngFor="let options of volumes" >{{options.volume}}</option>
            </select>
        </div>
        <div class="col-6">
            <h3>chose issue</h3>
            <select [(ngModel)]="issueselected"  name="issueselected" class="btn btn-outline-info" id="input">
                <option></option>
                <option *ngFor="let options of issuelist">{{options.issue}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <h3>book title</h3>
            <input [(ngModel)]="title" name="title" class="btn btn-outline-info" id="input" type="text">
        </div>
        <div class="col-4">
            <h3>Chose book</h3>
            <input  (change)="onChangebook($event)" name="ccvvccvv" type="file" class="btn btn-outline-info" id="input" >
        </div>
        <div class="col-4">
            <h3>page count</h3>
            <input [(ngModel)]="page_count" name="page_count" type="number" class="btn btn-outline-info" id="input" >
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>description</h3>
            <textarea  [(ngModel)]="description" name="description"  class="btn btn-outline-info" id="textarea" type="text"></textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h3>author</h3>
            <input  [(ngModel)]="author" name="author" class="btn btn-outline-info" id="input" type="text">
        </div>
        <div class="col-6">
            <h3>email</h3>
            <input  [(ngModel)]="email" name="email"   class="btn btn-outline-info" id="input" type="text">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <br>
            <button (click)="getBooks1()" id="input" class="btn btn-outline-info">create</button>
        </div>
    </div>
    <br>
</div>
<br><br>
</form>
{{volumeselected}} {{issueselected}}
<style>
    #textarea{
        width: 100%;
        height: 150px;
        text-align: left;
    }
    #input{
        width: 100%;
        height: 50px;
        text-align: left;
    }
    #text_center{
        text-align: center;
    }
    #porder{
        border-style: solid;
        border-radius: 10px;
    }
</style>