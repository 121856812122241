
<div id="nav">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" style="margin-right: 10px;">
          <a class="nav-link" routerLink="home" routerLinkActive="active">
            <img class="LOGO" src="../assets/images/JAUTSR1.png">
            <h4 id="text_hover">JAUTSR</h4>
          </a>
        </a>
        <A style="
        width: 100%;
    " href="#" class="nav-link">
          <h4>
            Journal of Aqaba University of Technology for Studies and Research
          </h4>
          <h4>
            مجلة جامعة العقبة للتكنولوجيا للدراسات والبحوث
          </h4>
        </A>
    </nav>
  </div>
  
  
  
  
  
  
  <router-outlet></router-outlet>
  <div id="footer" class="container-fuild">
    <div class="row">
      <div class="col-12 col-md-6" id="entext">
          <h1>{{footer.title}}</h1>
          <hr>
          <h2>{{footer.header}}</h2>
          <h3>location:{{footer.location}}</h3>
          <h3>Phone:{{footer.phone}}</h3>
          <h3>{{footer.fax}}</h3>
          <h3>P.O.Box 910122 , Amman 11191, Jordan</h3>
          <h3>E-mail:{{footer.email}}</h3>
      </div>  
      <div class="col-12 col-md-6" id="artext">
        <h1>{{footer.title_ar}}</h1>
        <hr>
        <h2>{{footer.header_ar}}</h2>
        <h3>{{footer.location}}</h3>
        <h3>الهاتف:{{footer.phone}}+</h3>
        <h3>فاكس : {{footer.fax}}+
  
        </h3>
        <h3>ص.ب. 910122 , عمان 11191 , الأردن</h3>
        <h3>البريد الايكتروني : {{footer.email}}</h3>
      </div> 
      <hr>
      <br>
      <h5 style="text-align: left;color: burlywood;margin-left: 17.5px;">
Powered by Computer Center 

 (BASEL ATEF).
      </h5> 
    </div>
  </div>