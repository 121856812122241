
<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="padding-left: 85px">
    <a class="navbar-brand" routerLink="/home"><span><strong>AUT
                Staff</strong></span></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                    <h6><i class="fas fa-user mr-2"></i>{{ _authService.username }}</h6>
                </a>
                <div style="top: 87%;" class="dropdown-menu dropdown-menu-left" aria-labelledby="navbarDropdown">
                    <a (click)="logout()" class="dropdown-item logout">Logout</a>
                </div>
            </li>
        </ul>
    </div>
</nav>


<div class="container-fuild" style="padding-top: 50px;">

    <div class="table-header">
        <div class="row">
            <div class="col-7">
                <h3>Manage Books</h3>
            </div>
            <div class="col-5">
                <div class="ml-auto">
                    <button routerLink="/author/home" routerLinkActive="router-link-active"
                        class="btn btn-light">Back</button>
                </div>
            </div>
        </div>
    </div>


    <div class="user-card">
        <div class="row">
            <div class="col-md-12 form">
                <form class="user-form">
                    <h3 style="padding-bottom: 10px;">Publish a Book</h3>

                    <div class="input-group">
                        <input name="first_name" type="text" class="form-control"
                            placeholder="Book Title" style="margin-right: 4px;" />
                        <input  name="last_name" type="text" class="form-control"
                            placeholder="Authors" style="margin-left: 4px;" />
                    </div>

                        <textarea class="form-control" name="last_name"placeholder="Short Description" id="" cols="30" rows="7"></textarea>
                    <!-- <input  name="username" type="text" class="form-control"
                        placeholder="Username"> -->
                        <br>

                        <div class="input-group">
                            <input name="first_name" type="text" class="form-control"
                                placeholder="Contact Email" style="margin-right: 4px;" />
                            <input  name="last_name" type="text" class="form-control"
                                placeholder="Contact Phone No." style="margin-left: 4px;" />
                        </div>

                        <form action="/action_page.php">
                            <input type="file" id="myFile" name="filename">
                          </form>
                    <hr>

                    <button (click)="saveUser()" class="btn btn-success btn-successs btn-block" type="submit">Create
                        User</button>
                </form>
            </div>
        </div>
    </div>




</div>