import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  notifications: any = [];
  fullname: string = 'User1'
  public classApplied = false;
  canAccess: boolean = false;

  constructor(private _apiService: ApiService, public _router: Router, public _authService: AuthService) {}



  ngOnInit(): void {
    this.canUserGetAccess();
    this.footer_data();
  }


  canUserGetAccess() {
    this._apiService.canUserGetAccess()
      .subscribe(data => this.canAccess = data[0]['fivenineattack']);
  }


  menuToggle() {
    this.classApplied = !this.classApplied;
  }



  footer:any;
  footer_data() {
    this._apiService.GET_Footer()
      .subscribe(data=>{this.footer=data});
  }

  logout() {
    this._authService.logout();
    window.location.href = '/login';
    window.location.reload();
  }
}
