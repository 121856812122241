import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-latest-en',
  templateUrl: './latest-en.component.html',
  styleUrls: ['./latest-en.component.scss']
})
export class LatestEnComponent implements OnInit {
  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.logvolume();
    this.logissue();
    this.getBooks();
    this.Current_Issue();
  }
  books: any;
  getBooks() {
    this._apiService.getBooks()
      .subscribe((arg: any) => this.books = arg.reverse());
  }
  openBook(url: string) {
    window.location.href = url;
  }
  x='CurrentIssue';
  sortby(value){
    this.x=value;
    this.x2=value;
  }
  x2='CurrentIssue';
  sortby2(value){
    this.x2=value;
  }
  //GET_Current_Issue
  CurrentIssue:any;
  Current_Issue(){
    this._apiService.GET_Current_Issue().subscribe(
      data=>(this.CurrentIssue=data)
    )
  }










  xxxxxxxxxxxxxxxxxx:any;
  logvolume() {
    this._apiService.volume().subscribe(
      data =>(this.xxxxxxxxxxxxxxxxxx = data)
      )
  }
  issuelist:any;
  logissue() {
    this._apiService.issue()
      .subscribe((arg: any) =>{
        this.issuelist = arg.reverse()
        console.log(arg.reverse())
      });
  }

  issueseected:any;
  issue_seected(value){
    this.issueseected=value;
    this.x='issueseected';
  }

}
