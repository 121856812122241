import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-publish-book',
  templateUrl: './publish-book.component.html',
  styleUrls: ['./publish-book.component.scss']
})
export class PublishBookComponent implements OnInit {

  constructor(
    public _authService: AuthService,
    private _router: Router,
    public appComponent: AppComponent,
    private _toastr: ToastrService) {}

  ngOnInit(): void {
  }

  saveUser() {
    setTimeout(() => {
    this._toastr.success(`Your has been sent for review successfully!`);
      this._router.navigate(['/author/home']);
    }, 1300)

  }

  

  logout() {
    this._authService.logout();
    this._router.navigate(['/home']);
  }
}
