import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'AimsandScope',
  templateUrl: './AimsandScope.component.html',
  styleUrls: ['./AimsandScope.component.scss']
})
export class AimsandScopeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
