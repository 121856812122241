import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthInterceptor } from './services/auth.interceptor';

import { HomeComponent } from './components/home/home.component';

import { LoginComponent } from './components/staff/login/login.component';
import { MainpageComponent } from './components/staff/mainpage/mainpage.component';

import { LatestEnComponent } from './components/journal/latest-en/latest-en.component';
import { VolumesEnComponent } from './components/journal/volumes-en/volumes-en.component';

import { EditorialEnComponent } from './components/boards/editorial-en/editorial-en.component';
import { AdvisoryEnComponent } from './components/boards/advisory-en/advisory-en.component';
import { PublishBookComponent } from './components/staff/publish-book/publish-book.component';
import { EthicsGuidelinesComponent } from './components/ethics-guidelines/ethics-guidelines.component';
import { ObjectiveComponent } from './components/objective/objective.component';
import { ForReviewerComponent } from './components/for-reviewer/for-reviewer.component';
import { ForAuthorComponent } from './components/for-author/for-author.component';
import { Current_Issue } from './components/main/Current_Issue/Current_Issue.component';
import { footer_component } from './components/main/footer/footer.component';
import { adminloginArComponent } from './components/main/login/login.component';
import { dashpordcomponent } from './components/main/dashbord/dash.component';
import { bookdashbord_component } from './components/main/books_dashbord/books.component';
import { AimsandScopeComponent } from './components/AimsandScope/AimsandScope.component';



@NgModule({
  declarations: [
    AimsandScopeComponent,
    Current_Issue,
    footer_component,
    adminloginArComponent,
    dashpordcomponent,
    bookdashbord_component,
    HomeComponent,
    AppComponent,
    LoginComponent,
    MainpageComponent,
    LatestEnComponent,
    VolumesEnComponent,
    EditorialEnComponent,
    AdvisoryEnComponent,
    PublishBookComponent,
    EthicsGuidelinesComponent,
    ObjectiveComponent,
    ForReviewerComponent,
    ForAuthorComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left'
    }),
  ],
  providers: [ApiService, AuthService, 
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],  
  bootstrap: [AppComponent]
})
export class AppModule { }
