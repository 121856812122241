import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ethics-guidelines',
  templateUrl: './ethics-guidelines.component.html',
  styleUrls: ['./ethics-guidelines.component.scss']
})
export class EthicsGuidelinesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
