import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-advisory-en',
  templateUrl: './advisory-en.component.html',
  styleUrls: ['./advisory-en.component.scss']
})
export class AdvisoryEnComponent implements OnInit {
  boards: any;
  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.getBoards();
  }

  getBoards() {
    this._apiService.getAdvisoryBoards()
      .subscribe(arg => this.boards = arg);
  }
}
