import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const baseUrl = 'http://jautsr.aut.edu.jo/backend/api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _http: HttpClient) { }

  public getBooks() {
    return this._http.get(`${baseUrl}/GetBooks`);
  };

  public getEditorialBoards() {
    return this._http.get(`${baseUrl}/GetEditorialBoards`);
  };
  public getAdvisoryBoards() {
    return this._http.get(`${baseUrl}/GetAdvisoryBoards`);
  };

  public canUserGetAccess() {
    return this._http.get(`${baseUrl}/canUserGetAccess`);
  };
  
  //************************ */

  //crud volume
  public volume() {
    return this._http.get(`${baseUrl}/Getvolume`);
  };
  public postvolume(formData) {
    return this._http.post<any>(`${baseUrl}/Getvolume`,formData);
  };
  
  //crud  issue
  public issue() {//all
    return this._http.get(`${baseUrl}/Getissue`);
  };
  public postissue(formData) {
    return this._http.post<any>(`${baseUrl}/Getissue`,formData);
  };
  public issue2(volume) {//filter
    return this._http.get(`${baseUrl}/Getissue/${volume}`);
  };

  //crud boook
  public getBooks2() {
    return this._http.get(`${baseUrl}/GetBooks`);
  };
  public postBooks(formData) {
    return this._http.post<any>(`${baseUrl}/GetBooks`,formData);
  };


    PUT_Footer(formData){//update***************************
    return this._http.put(`${baseUrl}/PUTfooter/1`,formData);
  }


  public GET_Footer(){//footer
    return this._http.get(`${baseUrl}/GETfooter`);
  };
  //p_Current_Issue
  PUT_Current_Issue(formData){//update***************************
    return this._http.put(`${baseUrl}/p_Current_Issue`,formData);
  }
  GET_Current_Issue(){//get***************************
    return this._http.get(`${baseUrl}/g_Current_Issue`);
  }

}
