import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-volumes-en',
  templateUrl: './volumes-en.component.html',
  styleUrls: ['./volumes-en.component.scss']
})
export class VolumesEnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
