
<header>
    <div class="background"></div>
    <div class="mask">
        <div class="container">
            <div class="context typewriter">
                <h1><span>The International Advisory Board</span></h1>
            </div>
        </div>
    </div>
    <div class="arrow"></div>
</header>



<div class="staff">
    <div class="container">
      
        <div class="staf" *ngFor="let item of boards">
            <h4>{{item.name_english}}</h4>
            <p>{{item.university_english}}</p>
            <br>
            <p class="blue"><i class="fas fa-envelope"></i> {{item.email}}</p>
        </div>
    </div>
</div>


