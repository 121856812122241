<div class="container-fuild">


    <header>
        <div class="background"></div>
        <div class="mask">
            <div class="container">
                <div class="context typewriter">
                    <h1 style="font-size: 45px; line-height: 70px"><span>Publication Ethics Guidelines</span></h1>
                </div>
            </div>
        </div>
        <div class="arrow"></div>
    </header>



    <div class="about-us-one">
        <div class="container">
            <p style="text-align: justify; font-size: 25px;">We expect authors submitting their work to the journal to attest that their work is original and has not been submitted or under consideration elsewhere. </p>
            <p style="text-align: justify; font-size: 25px;">The submitted work should not have libelous or defamatory statements. </p>
            <p style="text-align: justify; font-size: 25px;">We will be actively scrutinizing all submissions, as well as utilizing a plagiarism software, to ensure that authors adhere to the highest ethical standards.</p>
            <p style="text-align: justify; font-size: 25px;">Other ethical issues comprise of self-plagiarism, dual publication, and fabrications of data. All submitted to the Journal manuscripts will be pre-screened by the Editors. </p>
            <p style="text-align: justify; font-size: 25px;">The journal and its Editors take all cases of alleged ethical misconduct seriously. Each case will be investigated by seeking clarifications from all involved parties. The Journal and its Editors reserve the right to reject or withdraw any acceptance if the case of ethical misconduct is discovered. A retraction notice will be issued in the case of the established ethical misconduct of the published articles.</p>
                <button class='btn btn-warning' style='padding: 10px 20px; font-weight: 700; font-size: 20px;'><a href="https://www.docdroid.net/fzb8udw/arshadat-llmolf-pdf" target="_blank">ارشادات للمؤلف</a></button>

        </div>



    </div>


</div>