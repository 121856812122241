



<br>
<br>
<br>
<br>



<div class="center container">
    <div class="row">
        <div class="col-12 col-md-6 col-xl-3">
            <a href="/bookdashbord" ><button class="btnw btn btn-outline-warning">add ( volume & issue )</button></a>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
            <a href="/footer" ><button class="btnw btn btn-outline-warning">footer</button></a>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
            <a href="/CurrentIssue" ><button class="btnw btn btn-outline-warning">Current Issue</button></a>
        </div>
    </div>
</div>


<style>
    .center{
        text-align: center;
    }
    .btnw{
        width: 100%;
        margin-top: 50px;
    }
</style>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>