<div class="container-fuild">


    <header>
        <div class="background"></div>
        <div class="mask">
            <div class="container">
                <div class="context typewriter">
                    <h1 style="font-size: 45px; line-height: 70px"><span>Information for Authors</span></h1>
                </div>
            </div>
        </div>
        <div class="arrow"></div>
    </header>



    <div class="about-us-one">
        <div class="container">
            <h3 style="font-weight: 700;">Checklist</h3>
            <p style="text-align: justify; font-size: 25px;">Authors have to ensure their submission's compliance with all of the following items, and submissions may be returned to authors when the following guidelines are not adopted. </p>


            <ul>
                <li>The submission has not been previously published, nor is it before another journal for consideration.</li>
                <li>The submission file is in Microsoft Word format.</li>
                <li>All borrowed material should be referenced according to the Harvard referencing system.</li>
                <li>The text is single-spaced; uses a 12-point font; employs italics, rather than underlining (except with URL addresses); and all illustrations, figures, and tables are placed within the text at the appropriate points, not at the end.</li>
            </ul>

            <br>
            <h3 style="font-weight: 700;">Copyright Notice</h3>

            <p style="text-align: justify; font-size: 20px; font-weight: 600; opacity: 0.7">Journal of Aqaba University for Studies and Research (JAUTSR) is an open access journal, aiming to disseminate academic work as widely as possible. </p>
            <p style="text-align: justify; font-size: 20px; font-weight: 600; opacity: 0.7">Articles submitted to JAUTSR should not have been published before in their current or substantially similar form, or be under consideration for publication with another journal.  </p>
            <p style="text-align: justify; font-size: 20px; font-weight: 600; opacity: 0.7">Authors warrant that the publication is not an infringement of any existing copyright and will indemnify the publisher against any breach of such warranty. </p>
            <p style="text-align: justify; font-size: 20px; font-weight: 600; opacity: 0.7">For ease of dissemination and to ensure proper use, papers and contributions become the legal copyright of the publisher unless otherwise agreed.</p>
            <p style="text-align: justify; font-size: 20px; font-weight: 600; opacity: 0.7">Authors who publish with JAUTSR will retain the copyright in the underlying work but will grant all users the rights to copy, store and print for non-commercial use copies of their work.  </p>
            <p style="text-align: justify; font-size: 20px; font-weight: 600; opacity: 0.7">In case of death or retirement, any rights in the work will pass to the JAUTSR which will continue to make the work available in as wide a manner as possible to achieve the aims of open access and ensuring that an author's work continues to be available. </p>
            

            <br>

            <button class='btn btn-warning' style='padding: 10px 20px; font-weight: 700; font-size: 20px;'><a href="https://www.ejmanager.com/my/authors/" target="_blank">Manuscript Submission</a></button>
        </div>
    </div>


</div>