<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="padding-left: 85px">
    <a class="navbar-brand" routerLink="/home"><span><strong>AUT
                Staff</strong></span></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                    <h6><i class="fas fa-user mr-2"></i>{{ _authService.username }}</h6>
                </a>
                <div style="top: 87%;" class="dropdown-menu dropdown-menu-left" aria-labelledby="navbarDropdown">
                    <a (click)="logout()" class="dropdown-item logout">Logout</a>
                </div>
            </li>
        </ul>
    </div>
</nav>




<div class="container-fuild"  style="padding-top: 50px;">

    <div class="table-header">
        <div class="row">
            <div class="col-7">
                <h3>Your Books <span class="count">1</span></h3>
            </div>
            <div class="col-5">
                <div class="ml-auto">
                    <!-- <button data-toggle="collapse" data-target="#filter" aria-expanded="false"
                    aria-controls="filter"
                        class="btn btn-light" style="margin-right: 5px;"><i class="fas fa-search"></i></button>
 -->

                    <button routerLink="/author/publish-book" routerLinkActive="router-link-active"
                        class="btn btn-light">Publish a Book</button>

                     
                </div>
            </div>
        </div>
    </div>

    <div class="limiter">
        <div class="container-table100">
            <div class="wrap-table100">
                <div class="table" style="margin-bottom: 0 !important;">
                    <div class="roww header">
                        <div class="cell">
                            Book Title
                        </div>
                        <div class="cell">
                            Published Date
                        </div>
                        <div class="cell">
                            Status
                        </div>
                    </div>
                </div>
    
                <div class="filter">
                    <div>
                        <div>
                            <p style="    margin: 0;
                            font-size: 0.01px;">-</p>
                            <div class="spacee">
                                <div class="input-group">
                                    <input   name="filter" type="text"
                                        class="form-control" placeholder="Filter:" autocomplete="off" #searchInput>
                                    <!-- <div class="input-group-append">
                                        <button class="btn btn-success"  type="button">
                                            <i class="fa fa-search"></i>
                                        </button>
                                    </div> -->
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
    
    
                <div class="table">
                        <div class="roww" style="display: table;">
                            <div class="cell">
                                Priestess Of Yesterday

                            </div>
                            <div class="cell">
                                3rd Oct, 2021

                            </div>
                            <div class="cell">
                                <i class="nav-icon fas fa-circle"
                                style="color: rgb(248, 210, 42)"></i> Pending
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>