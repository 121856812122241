import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'bookdashbord',
  templateUrl: './books.component.html',
  //styleUrls: ['./books.component.scss']
})
export class bookdashbord_component {
  constructor(
    private formBuilder: FormBuilder,
    private _apiService: ApiService,
    public _router: Router ,
    ) { }

  books:any;
  volumes:any;
  issuelist: any;
  volume_issuse='title_of_volume issue';
  

  set_user:any;
  set_pass:any;
  session_data(){
      this.set_user= sessionStorage.getItem('name');
      this.set_pass= sessionStorage.getItem('password');
      if (this.set_user=="user"&&this.set_pass=="pass"){

      }
      else{
          this._router.navigateByUrl(`/login`).then(() => window.location.reload())
      }
  }





  //add new book
  volumeselected:any;
  issueselected:any;
  page_count:any;
  title:any;
  author:any;
  email:any;
  description:any;
  //get book
  
  show: boolean;
  showError: boolean;
  form: FormGroup;

  ngOnInit(): void {
    this.session_data();
    //gwt file
    this.form = this.formBuilder.group({
      ccvvccvv: [''],
    });
    console.log('>>>>>>>>>>>>>>>>>>>>>>>');
    this.logvolume();
    this.volume_issuse;
    this.show = false;
    this.showError = false;
    
    
    
    
  }

  
  logvolume() {
    this._apiService.volume()
      .subscribe((arg: any) =>{
        this.volumes = arg.reverse()
        console.log(arg.reverse())
      });
  }
  logissue() {
    this._apiService.issue2(this.volumeselected)
      .subscribe((arg: any) =>{
        this.issuelist = arg.reverse()
        console.log(arg.reverse())
      });
  }

  get_volume_issuse(wanted:string){
    this.volume_issuse=wanted;
    console.log(wanted)
    console.log(this.volume_issuse)
  }
  //**************************** */
  onChangebook(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('ccvvccvv').setValue(file);
    }
  }
  getBooks1() {
    
    const vi=this.volumeselected+' '+this.issueselected;
    const formData = new FormData();
    console.log(this.form.get('ccvvccvv').value)
    formData.append('upload_book', this.form.get('ccvvccvv').value);
    formData.append('volume_issue', vi);
    formData.append('title_english', this.title);
    formData.append('pages', this.page_count);
    formData.append('author', this.author);
    formData.append('email', this.email);
    formData.append('description', this.description);
    console.log(formData);
    this._apiService.postBooks(formData)
    .subscribe(
      (res) => {
        console.log(res);
        this.show = true;
      },
    );
  }
  newssue:any;
  involume:any;
  postnewissue() {
    const formData = new FormData();
    formData.append('issue', this.newssue);
    formData.append('issue_for_volume', this.involume);
    console.log(formData);
    this._apiService.postissue(formData)
    .subscribe(
      (res) => {
        console.log(res);
        this.show = true;
      },
    );
  }


  newvolume;any;

  postnewvolume() {
    
    const formData = new FormData();
    formData.append('volume', this.newvolume);
    console.log(formData);
    this._apiService.postvolume(formData)
    .subscribe(
      (res) => {
        console.log(res);
        this.cleer();
        this.show = true;
      },
    );
  }


  cleer(){
    this.newssue="";
    this.newvolume="";
    this.newssue="";
    this.newssue="";
    this.newssue="";
  }
}
