import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-editorial-en',
  templateUrl: './editorial-en.component.html',
  styleUrls: ['./editorial-en.component.scss']
})
export class EditorialEnComponent implements OnInit {
  boards: any;
  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.getBoards();
  }

  getBoards() {
    this._apiService.getEditorialBoards()
      .subscribe(arg => this.boards = arg);
  }
}
