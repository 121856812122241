import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  //styleUrls: ['./books.component.scss']
})
export class footer_component {
  constructor(
    private formBuilder: FormBuilder,
    private _apiService: ApiService,
    public _router: Router ,
    ) { }

  books:any;
  volumes:any;
  issuelist: any;
  volume_issuse='title_of_volume issue';
  

  set_user:any;
  set_pass:any;
  session_data(){
      this.set_user= sessionStorage.getItem('name');
      this.set_pass= sessionStorage.getItem('password');
      if (this.set_user=="user"&&this.set_pass=="pass"){

      }
      else{
          this._router.navigateByUrl(`/login`).then(() => window.location.reload())
      }
  }
  ngOnInit(): void {
    this.session_data();
    this.footer_data();


  }




  //GET_Footer




  footer:any;
  footer_data() {
    this._apiService.GET_Footer()
      .subscribe(data=>{this.footer=data});
  }

  //PUT_Foter
  v1:any;
  v2:any;
  v3:any;
  v4:any;
  v5:any;
  v6:any;
  v7:any;
  v8:any;
  v9:any;
  update(){
    const formData = new FormData();
    formData.append('header', this.v1);
    formData.append('header_ar', this.v2);
    formData.append('title', this.v3);
    formData.append('title_ar', this.v4);
    formData.append('location', this.v5);
    formData.append('location_ar', this.v6);
    formData.append('phone', this.v7);
    formData.append('fax', this.v8);
    formData.append('email', this.v9);
    this._apiService.PUT_Footer(formData)
    .subscribe( 
      data => { console.log('error in put file admin age');}
      );this.ngOnInit();
}
}