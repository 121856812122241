import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'Current_Issue',
  templateUrl: './Current_Issue.component.html',
})
export class Current_Issue {
    constructor(private x:ApiService,public _router: Router ) { }
    /* session var */
        set_user:any;
        set_pass:any;
        session_data(){
            this.set_user= sessionStorage.getItem('name');
            this.set_pass= sessionStorage.getItem('password');
            if (this.set_user=="user"&&this.set_pass=="pass"){

            }
            else{
                this._router.navigateByUrl(`/login`).then(() => window.location.reload())
            }
        }





    /* session var */

    ngOnInit(): void {
        this.session_data()
      }
         
    //PUT_Current_Issue
    v1:any;
  update(){
    const formData = new FormData();
    formData.append('Current_Issuefeald', this.v1);
    this.x.PUT_Current_Issue(formData)
    .subscribe( 
      data => { console.log('error in put file admin age');}
      );this.ngOnInit();
}
   

}