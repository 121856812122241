import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'adminlogin',
  templateUrl: './login.component.html',
})
export class adminloginArComponent  {
  constructor(public _router: Router ) { }
username:any;
password:any;
ngOnInit(): void {
  
}

log(){
  sessionStorage.setItem('name', this.username,);
  sessionStorage.setItem('password',this.password,);
  this._router.navigateByUrl(`/admins`).then(() => window.location.reload())
}
  
 public getdata(){
   console.log('>>>>>>>>>>>>>>>>>>>>>>>')
   this.username= sessionStorage.getItem('name');
   console.log(this.username)

 }



}
