<div class="container">
    <div class="row">
        <div class="col-6">
            <h3>title</h3>
            <input  class="btn btn-outline-success" [(ngModel)]="v3" type="" name="v3" id="input" >
        </div>
        <div class="col-6">
            <h3>title ar</h3>
            <input  class="btn btn-outline-success" [(ngModel)]="v4" type="" name="v4" id="input" >
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h3>header</h3>
            <input  class="btn btn-outline-success" [(ngModel)]="v1" type="" name="v1" id="input" >
        </div>
        <div class="col-6">
            <h3>header ar</h3>
            <input  class="btn btn-outline-success" [(ngModel)]="v2" type="" name="v2" id="input" >
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h3>location</h3>
            <input  class="btn btn-outline-success" [(ngModel)]="v5" type="" name="v5" id="input" >
        </div>
        <div class="col-6">
            <h3>location ar</h3>
            <input  class="btn btn-outline-success" [(ngModel)]="v6" type="" name="v6" id="input" >
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h3>phone</h3>
            <input  class="btn btn-outline-success" [(ngModel)]="v7" type="" name="v7" id="input" >
        </div>
        <div class="col-6">
            <h3>fax</h3>
            <input  class="btn btn-outline-success" [(ngModel)]="v8" type="" name="v8" id="input" >
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h3>email</h3>
            <input   class="btn btn-outline-success" [(ngModel)]="v9" type="" name="v9" id="input" >
        </div>
        <div class="col-6">
            <h3>update</h3>
            <button class="btn btn-outline-success" (click)="update()" id="input" >done</button>
        </div>
    </div>
</div>