<!DOCTYPE html>
<html>
    <head>
        <title></title>
    </head>
    <body>
        <div id="form" class="container">
            <div class="row">
                <div class="col">
                    <h1>username</h1>
                    <input class="btn btn-outline-success" [(ngModel)]="username" type="" name="username" id="input" >
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h1>username</h1>
                    <input class="btn btn-outline-success" [(ngModel)]="password" type="" name="password" id="input" >
                </div>
            </div>
            <br>
            <br>
            <button class="btn btn-outline-success" id="input" (click)="log()">log to admin staff</button>
        </div>
    </body>
</html>




<style>
    #input{
        width: 100%;
        height: 50px;
    }
    #{
        padding: 25px;
        margin: 25px;
    }
</style>