

<header>
    <div class="background"></div>
    <div class="mask">
        <div class="container">
            <div class="context typewriter">
                <h1><span>Latest Issue</span></h1>
            </div>
        </div>
    </div>
    <div class="arrow"></div>
</header>

<div class="container-fuilf">
    <div class="row">







        <div class="col-10 col-md-3">
            <div style="margin: 15px;" class="container-fuilf">
                <button  class=" fw btn btn-outline-warning" (click)="sortby('CurrentIssue')" type="button" >Current Issue</button>
                <br><br>
                <button  (click)="sortby('all')" type="button" class=" fw btn btn-outline-warning">all</button>
                <br><br>
                <button  (click)="sortby2('Volumes')" type="button" class=" fw btn btn-outline-warning">Volumes</button>
                <br><br>
                <div style="padding: 5px;" *ngIf="x2=='Volumes'">
                    <div *ngFor="let vitem of xxxxxxxxxxxxxxxxxx">
                        <h5>#{{vitem.volume}}</h5>
                        <div *ngFor="let i of issuelist">
                            <div>
                                <button class=" fw2 btn btn-outline-info" (click)="issue_seected(vitem.volume+' '+i.issue)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                                      </svg>
                                      {{i.issue}}
                                </button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>







        <div class="col-10 col-md-9">
            <div class="container-fuilf">
                


                <div class="staff">
                    <div class="container">


                <div *ngIf="x=='all'">
                    <h5>
                       All Issue 
                    </h5>
                    <div class="staf" *ngFor="let book of books">
                        <div class="row">
                            <div class="col-md-11">
                                <h4>{{book.title_english}}</h4>
                                <p>{{book.author}}</p>
                                <p>{{book.email}}</p>
                                <br>
                                <p class="blue"><i class="fas fa-file"></i> Pages: {{book.pages}}</p>
                            </div>
                            <div class="col-md-1 pdf" style="cursor: pointer;"
                                (click)="openBook(book.book)">
                                <i class="fas fa-file-pdf"></i>
                            </div>
        
        
                        </div>
                    </div>
                </div>
             
                <div *ngIf="x=='CurrentIssue'">
                    <h5>
                        Current Issue({{CurrentIssue.Current_Issuefeald}})
                    </h5>
                    <div *ngFor="let book of books">
                        <div *ngIf="book.volume_issue==CurrentIssue.Current_Issuefeald">
                            <div class="staf">
                                <div class="row">
                                    <div class="col-md-11">
                                        <h4>{{book.title_english}}</h4>
                                        <p>{{book.author}}</p>
                                        <p>{{book.email}}</p>
                                        <p></p>
                                        <br>
                                        <p class="blue"><i class="fas fa-file"></i> Pages: {{book.pages}}</p>
                                    </div>
                                    <div class="col-md-1 pdf" style="cursor: pointer;"
                                        (click)="openBook(book.book)">
                                        <i class="fas fa-file-pdf"></i>
                                    </div>
                    
                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






                <div *ngIf="x=='issueseected'">
                    <h5>
                        ({{issueseected}})
                    </h5>
                    <div *ngFor="let book of books">
                        <div *ngIf="book.volume_issue==issueseected">
                            <div class="staf">
                                <div class="row">
                                    <div class="col-md-11">
                                        <h4>{{book.title_english}}</h4>
                                        <p>{{book.author}}</p>
                                        <p>{{book.email}}</p>
                                        <p></p>
                                        <br>
                                        <p class="blue"><i class="fas fa-file"></i> Pages: {{book.pages}}</p>
                                    </div>
                                    <div class="col-md-1 pdf" style="cursor: pointer;"
                                        (click)="openBook(book.book)">
                                        <i class="fas fa-file-pdf"></i>
                                    </div>
                    
                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








            </div>
        </div>



        
        
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link">Previous</a></li>
                        <li class="page-item"><a class="page-link">1</a></li>
                        <li class="page-item"><a class="page-link">2</a></li>
                        <li class="page-item"><a class="page-link">3</a></li>
                        <li class="page-item"><a class="page-link">Next</a></li>
                    </ul>
                </nav>
            </div>
        </div>




    </div>
</div>













