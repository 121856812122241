<div class="container-fuild">
    
    <div class="about-us-two">
        <div class="container">
            <div class="row">
                <div class="col-md-6 space">
                    <div class="background"
                        style="background: url('../../../assets/images/87bf2f87-6fc7-4511-9d12-62ff4724b5eb.jpg'); background-repeat: no-repeat; background-size: cover; border-radius: 4px;">
                    </div>
                </div>
                <div class="col-md-6 prop">
                    <div class="row">
                        <div class="col-md-12 colorlib-heading animate-box">
                            <h2>Aims and Scope</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 animate-box">
                            <p>JAUTSR aims to promote interdisciplinary research in humanities and natural sciences. The
                                journal publishes original research papers on current topics in the fields of humanities
                                and natural sciences such as Psychology, Management, Economics, Accounting, Finance,
                                History, Sociology, Politics, Media, Law, Linguistics, Literature, Anthropology,
                                Communication, Women Studies, Civil Engineering, Architecture Engineering, Software
                                Engineering, Artificial Intelligence, Pharmacy, and Allied Medical Sciences. The journal
                                seeks to encourage high quality research on current topics and topics that require
                                interactions among researchers from various backgrounds.</p>
                            <!-- <ul>
                            <li>Power and energy engineering including electricity generation by means of renewable and traditional energy sources</li>
                            <li>Communications engineering</li>
                            <li>Electromagnetics</li>
                            <li>Microelectronics</li>
                            <li>Nano-circuits and systems</li>
                            <li>Mechatronics</li>
                            <li>Computer engineering</li>
                            <li>Biomedical engineering</li>
                        </ul> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="mission-and-vision">
        <div class="container">
            <div class="row">
                <div class="col-md-6 prop mission">
                    <div class="row">
                        <div class="col-md-12 animate-box">
                            <!-- <h2>Abstracting and Indexing</h2>
                        <p>JJEE is currently indexed/abstracted in the following databases:</p>
                        <ul>
                            <li>ISC (Islamic World Science Citation Center)</li>
                            <li>Crossref</li>
                            <li>CiteFactor</li>
                            <li>Google Scholar</li>
                            <li>Ulrich's Periodical Directory</li>
                            <li>Studylib</li>
                            <li>Road</li>
                        </ul> -->
                            <h2>Publishing Schedule</h2>
                            <p>JAUTSR publishes - online and as a hard copy - four issues per year (June and December).</p>


                        </div>
                    </div>
                </div>

                <div class="col-md-6 prop mission">
                    <div class="row">
                        <div class="col-md-12 animate-box">

                            <h2>Management and Funding</h2>
                            <p>Journal of Aqaba University of Technology for Studies and Research is published by Aqaba
                                University of Technology located in Jordan.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>