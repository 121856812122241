import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private _apiService: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getBooks();
    this.Current_Issue();
    this.logvolume();
    this.logissue();
  }
  title='Current Issue';




  optactiv="CurrentIssue";
  opt(value){
    this.title='Current Issue';
    this.optactiv=value;
  }



  
  openBook(url: string) {
    window.location.href = url;
  }

  books: any;
  getBooks() {
    this._apiService.getBooks()
      .subscribe((arg: any) => this.books = arg.reverse());
  }
  //GET_Current_Issue
  CurrentIssue:any;
  Current_Issue(){
    this._apiService.GET_Current_Issue().subscribe(
      data=>(this.CurrentIssue=data)
    )
  }








  x2='CurrentIssue';
  sortby2(value){
    this.x2=value;
  }
  xxxxxxxxxxxxxxxxxx:any;
  logvolume() {
    this._apiService.volume().subscribe(
      data =>(this.xxxxxxxxxxxxxxxxxx = data)
      )
  }
  issuelist:any;
  logissue() {
    this._apiService.issue()
      .subscribe((arg: any) =>{
        this.issuelist = arg.reverse()
        console.log(arg.reverse())
      });
  }

  issueseected:any;
  issue_seected(value){
    this.title=value;
    this.issueseected=value;
    this.optactiv='issueseected';  
    this.x2='issueseected';  
  }




}


